import React, { useContext } from "react";
import placeholderImg from "../../img/placeholder.jpeg";
import Rating from "react-rating";
import useAuth from "../../hooks/hooks.auth";
import useHttp from "../../hooks/hooks.http";
import { HeaderContext } from "../../context/HeaderContext";

function ProductCard({ product }) {
  const { token } = useAuth();
  const { request } = useHttp();
  const { add } = useContext(HeaderContext);

  async function stockClickHandler(event) {
    try {
      if (token)
        await request(
          `/api/subscriptions/products/${product.id}/`,
          "POST",
          {},
          {
            Authorization: `Bearer ${token}`,
          }
        );
      event.target.innerText = "Вы успешно подписаны на данный товар";
    } catch (e) {
      event.target.innerText = "Вы уже подписаны";
    }
  }
  function addClickHandler(event) {
    const status = add(product);
    if (!status) {
      const error = event.target.parentElement.querySelector(".error");
      if (!error)
        event.target.insertAdjacentHTML(
          "beforebegin",
          `<p class="error">Товар заканчивается, Вы можете заказать не больше ${product.amount} штук</p>`
        );
    } else {
      animationAddToBasket(event.target.closest(".popular__content-item"));
    }
  }
  function animationAddToBasket(image) {
    const cloneProduct = image.cloneNode(true);
    cloneProduct.style.position = "fixed";
    cloneProduct.style.pointerEvents = "none";
    const productPosition = getPosition(image);
    cloneProduct.style.top = `${productPosition.top}px`;
    cloneProduct.style.left = `${productPosition.left}px`;
    cloneProduct.style.transition = "transform 2s ease, opacity 1s ease";
    cloneProduct.style.transform = `translate3d(0px, 0px, 0px) scale(1)`;
    cloneProduct.style.height = `auto`;
    cloneProduct.style.zIndex = `1000001`;
    document.body.insertAdjacentElement("beforeend", cloneProduct);
    console.log(document.querySelector("#basket"));
    const basketPosition = getPosition(document.querySelector("#basket"));
    const cloneProductPosition = getPosition(cloneProduct);
    cloneProduct.style.transformOrigin = "top right";
    cloneProduct.style.transform = `translate3d(${
      basketPosition.x -
      cloneProductPosition.x -
      cloneProductPosition.width / 1.2
    }px, ${basketPosition.y - cloneProductPosition.y}px, 0px) scale(0)`;
    cloneProduct.style.opacity = `0`;
    setTimeout(() => {
      cloneProduct.remove();
    }, 1000 + Math.abs(basketPosition.x - cloneProductPosition.x));
  }
  const getPosition = (element) => {
    return element.getClientRects()[0];
  };
  return (
    <div
      key={product.id}
      className={
        "popular__content-item popular-product product swiper-slide" +
        " " +
        (!product?.in_stock ? "no-avl" : "")
      }
    >
      <a
        href={"/product/" + product.link + "/"}
        className="popular-product__image product-image"
      >
        <img loading="lazy" src={product.main_image || placeholderImg} alt="" />
      </a>
      <div className="popular-product__content-box product-content-box">
        <div className="popular-product__content product-content">
          <h4 className="popular-product__title product-title">
            <a
              href={"/product/" + product.link + "/"}
              className="popular-product__link product-link"
            >
              {product.name}
            </a>
          </h4>
          <p className="popular-product__subtitle product-subtitle">
            {product.description}
          </p>
        </div>
        <div className="popular-product__info product-info">
          <div className="popular-product__price product-price">
            <div className="product-price__current product-price__current">
              {product.discounted_price} тг
            </div>
            {product.total_discount ? (
              <div className="product-price__old product-price__old">
                {product.price} тг
              </div>
            ) : (
              ""
            )}
          </div>
          {product.reviews.length && product?.in_stock ? (
            <div className="popular-product__rating product-rating">
              <Rating
                readonly={true}
                emptySymbol={"rating-item"}
                initialRating={product.rating}
                fullSymbol={"rating-item-fill"}
              />
              <div className="product-rating__value">
                отзывов: {product.reviews.length}
              </div>
            </div>
          ) : (
            ""
          )}
          {!product?.in_stock ? <p>Нет в наличии</p> : ""}
        </div>
        <a href={"/product/" + product.link + "/"} className="product__link">
          Смотреть товар
        </a>
        {product?.in_stock && (
          <button onClick={addClickHandler} className="product__link">
            {"Добавить в корзину"}
          </button>
        )}
        {!!token && !product?.in_stock && (
          <button
            onClick={!product?.is_subscribed ? stockClickHandler : undefined}
            className="product__link"
          >
            {product?.is_subscribed
              ? "Вы уже подписаны"
              : "Сообщить о поступлении"}
          </button>
        )}
        {!token && !product?.in_stock && (
          <p>Зайдите в аккаунт, чтобы подписаться на обновление товара</p>
        )}
      </div>
    </div>
  );
}

export default ProductCard;
