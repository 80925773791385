import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../../context/ProfileContext";
import { HeaderContext } from "../../context/HeaderContext";
import { useForm } from "../../hooks/hooks.form";
import InputSelect from "../InputSelect";
import useHttp from "../../hooks/hooks.http";
import Spoiler from "../Spoiler";
export default function CheckoutForm({
  priceData,
  products,
  discountText,
  promoChangeHandler,
  cityChangeHandler,
}) {
  const { userData } = useContext(ProfileContext);
  const { request } = useHttp();
  const [geoNames, setGeoNames] = useState([]);
  const { formChangeHandler, formSubmitHandler, form, setForm, loading } =
    useForm(onSuccess, {}, true);
  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(() => {
    if (!geoNames.length)
      (async () => {
        try {
          const data = await request("/api/geonames/", "GET");
          setGeoNames(
            data.results.map((geoName) => ({
              name: geoName.alternate_name,
              value: geoName.alternate_name,
            }))
          );
        } catch (e) {}
      })();
  }, []);
  useEffect(() => {
    if (userData)
      setForm({
        text: {
          customer_city: userData?.info.city || "",
          customer_street: userData?.info.street || "",
          customer_house: userData?.info.house || "",
          customer_floor: userData?.info.floor || "",
          customer_apartment: userData?.info.apartment || "",
          customer_first_name: userData?.first_name || "",
          customer_last_name: userData?.last_name || "",
          customer_email: userData?.email || "",
          customer_phone: userData?.info.phone || "",
          customer_entrance: userData?.info.entrance || "",
          address_comment: "",
        },
        radio: {
          payment_method: "KASPI_BILL",
        },
      });
    else
      setForm({
        text: {
          customer_city: "",
          customer_street: "",
          customer_house: "",
          customer_floor: "",
          customer_apartment: "",
          customer_first_name: "",
          customer_last_name: "",
          customer_email: "",
          customer_phone: "",
          customer_entrance: "",
          address_comment: "",
        },
        radio: {
          payment_method: "KASPI_BILL",
        },
      });
  }, [userData]);
  useEffect(() => {
    if (products) {
      setForm((prevState) => ({
        ...prevState,
        array: {
          products: products.map((product) => ({
            product: product.id,
            amount: product.count,
          })),
        },
      }));
    }
  }, [products]);
  useEffect(() => {
    if (!priceData.is_delivery_calculated) {
      setForm((prevState) => ({
        ...prevState,
        radio: {
          payment_method: "KASPI_BILL",
        },
      }));
      const input = document.querySelector('input[name="payment_method"]');
      if (input) input.checked = true;
    }
  }, [priceData]);
  function onSuccess(data) {
    setLoadingPage(true);
    if (data && data.payment_url) {
      window.location.replace(data.payment_url);
    } else if (data) {
      window.location.replace("/#success_popup");
    }
  }

  return (
    <>
      <section className="page__checkout-form checkout-form">
        <h1 className="checkout-form__title">Оформление заказа</h1>
        <form
          data-method={"POST"}
          action={"/api/orders/"}
          onSubmit={formSubmitHandler}
          className="checkout-form__items"
        >
          <div className="checkout-form__item">
            <div className="checkout-form__item-title">Способ оплаты</div>
            <div className="checkout-form__item-inputs-flex">
              {/*<div className="checkout-form__item-input-checkbox-box">*/}
              {/*  <input*/}
              {/*    type="radio"*/}
              {/*    name="payment_method"*/}
              {/*    value={"CARD"}*/}
              {/*    id={"input-card"}*/}
              {/*    onChange={formChangeHandler}*/}
              {/*    defaultChecked={true}*/}
              {/*    className="checkout-form__item-input-checkbox-input"*/}
              {/*  />*/}
              {/*  <label*/}
              {/*    htmlFor={"input-card"}*/}
              {/*    className="checkout-form__item-input-checkbox-label"*/}
              {/*  >*/}
              {/*    Карта*/}
              {/*  </label>*/}
              {/*</div>*/}
              <div className="checkout-form__item-input-checkbox-box">
                <input
                  type="radio"
                  name="payment_method"
                  value={"KASPI_BILL"}
                  id={"input-kaspi"}
                  onChange={formChangeHandler}
                  className="checkout-form__item-input-checkbox-input"
                />
                <label
                  htmlFor={"input-kaspi"}
                  className="checkout-form__item-input-checkbox-label"
                >
                  Счет на kaspi.kz
                </label>
              </div>
              {priceData.is_delivery_calculated && (
                <div className="checkout-form__item-input-checkbox-box">
                  <input
                    id={"input-cash"}
                    name={"payment_method"}
                    value={"CASH"}
                    type="radio"
                    onChange={formChangeHandler}
                    className="checkout-form__item-input-checkbox-input"
                  />
                  <label
                    htmlFor={"input-cash"}
                    className="checkout-form__item-input-checkbox-label"
                  >
                    Наличные
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="checkout-form__info">
            <Spoiler
              title={"Важная информация"}
              spoilerClass={"spoiler"}
              isLoaded={true}
              style={{
                marginBottom: 20,
                paddingBottom: 0,
              }}
              content={
                <>
                  В соответствии с Законом «О защите прав потребителей»
                  парфюмерия и косметика входят в перечень непродовольственных
                  товаров надлежащего качества, не подлежащих возврату или
                  обмену на аналогичный товар других размера, формы, габарита,
                  фасона, расцветки или комплектации.
                  <br />
                  <br />
                  Просим вас внимательно изучать заказ перед покупкой.
                </>
              }
            />
            <p>
              <span style={{ fontWeight: "bold" }}>
                Стоимость доставки по Алматы - 1900 тенге.
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Бесплатная доставка по Алматы на заказ суммой от 15000₸.
              </span>
            </p>
            Доставка заказов, сделанных до 12:00, осуществляется день в день.
            После 12:00 - на следующий день. Мы так же учитываем все ваши
            комментарии и пожелания.
            <br />
            <br />
            Оплата доставки производится непосредственно курьеру при получении
            посылки.
            <br />
            <br />
            {!priceData?.is_delivery_calculated &&
              "Доставка вне Алматы отправляется курьерской компанией и рассчитывается отдельно"}
            <br />
          </div>
          <div className="checkout-form__item">
            <div className="checkout-form__item-title">Адрес доставки</div>
            <div className="checkout-form__item-inputs">
              <InputSelect
                name="customer_city"
                defaultValue={userData?.info.city}
                inputBoxClass={"checkout-form__item-input-box reverse"}
                labelClass={"checkout-form__item-input-label"}
                inputClass={"checkout-form__item-input-input"}
                label={"Город"}
                changeHandler={(e) => {
                  formChangeHandler(e);
                  cityChangeHandler(e);
                }}
                items={geoNames}
                htmlFor={"customer_city"}
              />
              <div className="checkout-form__item-input-box">
                <label className="checkout-form__item-input-label req">
                  Улица
                </label>
                <input
                  type="text"
                  onInput={formChangeHandler}
                  defaultValue={userData?.info.street}
                  required={true}
                  name="customer_street"
                  className="checkout-form__item-input-input"
                />
              </div>
              <div className="checkout-form__item-input-box">
                <label className="checkout-form__item-input-label req">
                  Дом
                </label>
                <input
                  onInput={formChangeHandler}
                  name={"customer_house"}
                  required={true}
                  defaultValue={userData?.info.house}
                  type="text"
                  className="checkout-form__item-input-input"
                />
              </div>
              <div className="checkout-form__item-input-box">
                <label className="checkout-form__item-input-label">Этаж</label>
                <input
                  onInput={formChangeHandler}
                  name={"customer_floor"}
                  defaultValue={userData?.info.floor}
                  type="text"
                  className="checkout-form__item-input-input"
                />
              </div>
              <div className="checkout-form__item-input-box">
                <label className="checkout-form__item-input-label">
                  Номер квартиры / офиса
                </label>
                <input
                  onInput={formChangeHandler}
                  name={"customer_apartment"}
                  defaultValue={userData?.info.apartment}
                  type="text"
                  className="checkout-form__item-input-input"
                />
              </div>
              <div className="checkout-form__item-input-box">
                <label className="checkout-form__item-input-label">
                  Подъезд
                </label>
                <input
                  onInput={formChangeHandler}
                  name={"customer_entrance"}
                  defaultValue={userData?.info.entrance}
                  type="text"
                  className="checkout-form__item-input-input"
                />
              </div>
              <div className="checkout-form__item-input-box">
                <label className="checkout-form__item-input-label">
                  Комментарий к доставке
                </label>
                <textarea
                  onInput={formChangeHandler}
                  placeholder={"Доставка после 18:00 или нужна сдача"}
                  name={"address_comment"}
                  className="checkout-form__item-input-input"
                />
              </div>
            </div>
          </div>
          <div className="checkout-form__item">
            <div className="checkout-form__item-title">
              Информация о покупателе
            </div>
            <div className="checkout-form__item-inputs">
              <div className="checkout-form__item-input-box">
                <label className="checkout-form__item-input-label req">
                  Имя
                </label>
                <input
                  onInput={formChangeHandler}
                  type="text"
                  required={true}
                  defaultValue={userData?.first_name}
                  name="customer_first_name"
                  className="checkout-form__item-input-input"
                />
              </div>
              <div className="checkout-form__item-input-box">
                <label className="checkout-form__item-input-label req">
                  Фамилия
                </label>
                <input
                  onInput={formChangeHandler}
                  name={"customer_last_name"}
                  required={true}
                  defaultValue={userData?.last_name}
                  type="text"
                  className="checkout-form__item-input-input"
                />
              </div>
              <div className="checkout-form__item-input-box">
                <label className="checkout-form__item-input-label req">
                  Email
                </label>
                <input
                  onInput={formChangeHandler}
                  name={"customer_email"}
                  defaultValue={userData?.email}
                  required={true}
                  type="email"
                  className="checkout-form__item-input-input"
                />
              </div>
              <div className="checkout-form__item-input-box">
                <label className="checkout-form__item-input-label req">
                  Номер телефона
                </label>
                <input
                  onInput={formChangeHandler}
                  name={"customer_phone"}
                  required={true}
                  defaultValue={userData?.info.phone}
                  type="text"
                  className="checkout-form__item-input-input"
                />
              </div>
            </div>
          </div>
          <div className="checkout-form__item">
            <div className="checkout-form__item-inputs-flex">
              <div
                style={{ flex: 0.5 }}
                className="checkout-form__item-input-checkbox-box"
              >
                <input
                  type="radio"
                  name="use_brand_box"
                  value={true}
                  id={"use_brand_box_true"}
                  onChange={formChangeHandler}
                  defaultChecked={true}
                  className="checkout-form__item-input-checkbox-input"
                />
                <label
                  htmlFor={"use_brand_box_true"}
                  className="checkout-form__item-input-checkbox-label"
                >
                  В фирменную коробку
                </label>
              </div>
              <div
                style={{ flex: 0.5 }}
                className="checkout-form__item-input-checkbox-box"
              >
                <input
                  type="radio"
                  name="use_brand_box"
                  value={false}
                  id={"use_brand_box_false"}
                  onChange={formChangeHandler}
                  className="checkout-form__item-input-checkbox-input"
                />
                <label
                  htmlFor={"use_brand_box_false"}
                  className="checkout-form__item-input-checkbox-label"
                >
                  В обычную коробку**
                </label>
                <span style={{ marginTop: 10, display: "block" }}>
                  **Выбирая этот вариант, ваша покупка становится экологичней
                </span>
              </div>
            </div>
            <br />
          </div>
          <div className="checkout-form__item">
            <div className="checkout-form__item-title">
              У меня есть промокод
            </div>
            <div className="checkout-form__item-inputs">
              <div className="checkout-form__item-input-box">
                <label className="checkout-form__item-input-label">
                  Введите промокод
                </label>
                <input
                  onInput={(e) => {
                    formChangeHandler(e);
                    promoChangeHandler(e);
                  }}
                  type="text"
                  name="promocode"
                  className="checkout-form__item-input-input"
                />
              </div>
            </div>
          </div>
          <div className="checkout-form__info">
            <p>{discountText}</p>
          </div>
          <div className="checkout-form__item">
            {userData && parseInt(userData?.info.bonus_amount) !== 0 ? (
              <div className="checkout-form__item-checkbox">
                <input
                  type="checkbox"
                  name="spend_bonus"
                  id={"spend_bonus"}
                  onChange={formChangeHandler}
                  defaultChecked={false}
                  className="checkout-form__item-checkbox-input"
                />
                <label
                  htmlFor={"spend_bonus"}
                  className="checkout-form__item-checkbox-label"
                >
                  Потратить бонусы: {parseInt(userData?.info.bonus_amount)} тг
                </label>
              </div>
            ) : (
              ""
            )}
            {!!priceData.order_discount && priceData.order_discount !== 0 && (
              <>
                <span className={"checkout-form__item-price-info"}>
                  Скидка {priceData.order_discount}%
                </span>
                <br />
              </>
            )}
            {!!priceData.delivery_price && (
              <>
                <span className={"checkout-form__item-price-info"}>
                  Доставка {priceData.delivery_price}₸
                </span>
                <br />
              </>
            )}
            <div className="basket__sum">
              <span>Итого: </span>
              {priceData.order_discount ? (
                <>
                  <span
                    className={"product-price__old"}
                    style={{ marginRight: 10 }}
                  >
                    {priceData.total_sum
                      ?.toLocaleString("en")
                      .replaceAll(",", " ")}
                  </span>
                  {priceData.total_paid
                    ?.toLocaleString("en")
                    .replaceAll(",", " ")}
                </>
              ) : (
                priceData.total_paid?.toLocaleString("en").replaceAll(",", " ")
              )}
              ₸
            </div>
            <button
              type={"submit"}
              style={{
                pointerEvents: loading || loadingPage ? "none" : "auto",
              }}
              disabled={!products?.length || loading || loadingPage}
              className="basket__checkout"
            >
              {form.radio?.payment_method === "CARD" ? "Оплатить" : "Далее"}
            </button>
            <br />
            <span className={"checkout-form__item-price-info"}>
              Согласен с условиями{" "}
              <a
                style={{ color: "blue" }}
                target="_blank"
                referrerPolicy="no-referrer"
                href="https://mamaorganic.kz/media/public-offer.pdf"
              >
                Публичной оферты
              </a>
            </span>
          </div>
        </form>
      </section>
    </>
  );
}
